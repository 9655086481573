import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
import { HideDemo } from '../../../../components/Demos';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#hide",
        "aria-label": "hide permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hide`}</h1>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hide`}</code>{` modifier lets you hide the popper if it appears to be detached from
its reference element, or attached to nothing at all. This can occur when the
reference element is inside a scrolling container and the popper is in a
different context.`}</p>
    <x-ad />
    <p>{`It adds attributes to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`state.attributes`}</code>{`:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`data-popper-reference-hidden`}</code>{`: This attribute gets applied to the popper when
the reference element is fully clipped and hidden from view, which causes the
popper to appear to be attached to nothing.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`data-popper-escaped`}</code>{`: This attribute gets applied when the popper escapes the
reference element's boundary (and so it appears detached).`}</li>
    </ul>
    <p>{`To hide the popper, apply some CSS:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Hide the popper when the reference is hidden */`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#popper[data-popper-reference-hidden]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`visibility`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`pointer-events`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` avoid using properties like `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`display: none`}</code>{`. This doesn't allow
Popper to read its dimensions which can cause jitter issues.`}</p>
    </blockquote>
    <h2 {...{
      "id": "demo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#demo",
        "aria-label": "demo permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Demo`}</h2>
    <p>{`The popper turns partially transparent when the popper escapes the reference's
clipping container. It then becomes invisible when the reference is hidden
entirely.`}</p>
    <HideDemo mdxType="HideDemo" />
    <h2 {...{
      "id": "phase",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#phase",
        "aria-label": "phase permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Phase`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`main`}</code></p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#options",
        "aria-label": "options permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Options`}</h2>
    <p>{`This modifier currently has no options.`}</p>
    <h2 {...{
      "id": "data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#data",
        "aria-label": "data permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Data`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "flow"
    }}><pre parentName="div" {...{
        "className": "language-flow"
      }}><code parentName="pre" {...{
          "className": "language-flow"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  isReferenceHidden`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token type tag"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  hasPopperEscaped`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token type tag"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  referenceClippingOffsets`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Offsets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  popperEscapeOffsets`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Offsets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// A positive number indicates it's overflowing on that side`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Offsets `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  top`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token type tag"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  right`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token type tag"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  bottom`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token type tag"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  left`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token type tag"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      